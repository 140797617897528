import React, { useEffect, useState } from "react";
import JumboListNoDataPlaceHolder from "@jumbo/components/JumboList/components/JumboListNoDataPlaceHolder";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { Card, IconButton, Tooltip, Typography, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  addProduct,
  deleteProduct,
  getProducts,
  updateProduct,
} from "app/services/api/product";
import ProductItem from "./ProductItem";
import { useJumboDialog } from "@jumbo/components/JumboDialog/hooks/useJumboDialog";
import JumboSearch from "@jumbo/components/JumboSearch";
import AddBoxIcon from "@mui/icons-material/AddBox";
import ProductForm from "./ProductForm";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const { getAuthUser } = useJumboAuth();
  const {
    customerId,
    customer: { productLanguages },
  } = getAuthUser();
  const { t, i18n } = useTranslation();
  const { showDialog, hideDialog } = useJumboDialog();
  const [searchName, setSearchName] = useState();
  const navigate = useNavigate();

  const selectedLanguage =
    (productLanguages && productLanguages[0]?.code) || i18n.language;

  const [products, setProducts] = useState([]);
  const [productCategories, setProductCategories] = useState([]);
  const [productAttributes, setProductAttributes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const loadProducts = async () => {
    await getProducts(customerId).then((response) => {
      const { products, categories, attributes } = response || {};
      if (products === "no products") {
        return;
      }
      setProductCategories(categories);
      setProductAttributes(attributes);
      const productArr = Object.keys(products || {}).map((id) => ({
        ...products[id],
        id,
      }));
      const filteredProducts = searchName
        ? productArr.filter(
            (product) =>
              product?.metadata?.name &&
              product.metadata.name[selectedLanguage]
                .toLowerCase()
                .includes(searchName)
          )
        : productArr;
      setProducts(filteredProducts || []);
    });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    searchName !== undefined && loadProducts();
  }, [searchName, selectedLanguage]);

  const handleDelete = async (product) => {
    hideDialog();
    setLoading(true);
    try {
      await deleteProduct(product.url, customerId);
      await loadProducts();
      const message = `${t("buttons.delete")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.delete")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const openModal = (type, product) => {
    showDialog({
      variant: "default",
      title: t(`pages.products.${type}`),
      sx: {
        "& .MuiDialog-container": {
          alignItems: "flex-start",
        },
      },
      content: (
        <ProductForm
          product={product}
          onAddProduct={handleAddProduct}
          onEditProduct={handleEditProduct}
          onCancel={hideDialog}
        />
      ),
    });
  };

  const handleAddProduct = async () => {
    setLoading(true);
    hideDialog();
    try {
      const url = await addProduct(customerId);
      await loadProducts();
      const message = `${t("buttons.add")} ${t("notifications.wasSuccessful")}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
      navigate(`/product/${url}`);
    } catch (error) {
      const message = `${t("buttons.add")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setLoading(false);
    }
  };
  const handleEditProduct = async (product) => {
    setLoading(true);
    hideDialog();
    try {
      await updateProduct(product);
      await loadProducts();
      const message = `${t("buttons.edit")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.edit")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
      setLoading(false);
    }
  };

  const deleteConfirmation = (product) => {
    showDialog({
      variant: "confirm",
      title: t("widgets.confirmDialog.areYouSure"),
      onYes: () => handleDelete(product),
      onNo: () => hideDialog(),
    });
  };

  return (
    <React.Fragment>
      <Typography variant={"h2"} mb={3}>
        {t("sidebar.menuItem.products")}
      </Typography>
      <Stack
        direction="row"
        display="flex"
        alignItems="center"
        mb={2}
        spacing={1}
      >
        <Card sx={{ mb: 0, width: "50%" }}>
          <JumboSearch
            page="products"
            onChange={setSearchName}
            sx={{
              width: "100%",
            }}
            placeholder={t("pages.presentation.searchPresentation")}
          />
        </Card>
        <Tooltip title={t("pages.products.addProduct")}>
          <IconButton onClick={() => openModal("addProduct")}>
            <AddBoxIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      {products?.length ? (
        products.map((product, index) => (
          <ProductItem
            key={index}
            item={product}
            disabled={loading}
            categories={productCategories}
            onDelete={deleteConfirmation}
            onEdit={() => {
              navigate(`/product/${product.url}`);
            }}
            productLanguages={productLanguages}
          />
        ))
      ) : (
        <JumboListNoDataPlaceHolder />
      )}
    </React.Fragment>
  );
};

export default Products;
