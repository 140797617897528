import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { useJumboTheme } from "@jumbo/hooks";
import Div from "@jumbo/shared/Div";
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  ImageListItem,
  ImageListItemBar,
  MenuItem,
  Modal,
  Stack,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import BrowserUpdatedIcon from "@mui/icons-material/BrowserUpdated";
import DeleteIcon from "@mui/icons-material/Delete";
import Images from "../media/images/Images";
import { PROD_URL } from "app/services/config";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import {
  getProduct,
  getProducts,
  updateProduct,
} from "app/services/api/product";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { Field, Form, Formik } from "formik";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import FastRewindIcon from "@mui/icons-material/FastRewind";

const EditProduct = () => {
  const { url } = useParams();
  const { getAuthUser } = useJumboAuth();
  const {
    customerId,
    customer: { productLanguages },
  } = getAuthUser();
  const { t, i18n } = useTranslation();
  const { theme } = useJumboTheme();
  const [updating, setUpdating] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [openGallerySelector, setOpenGallerySelector] = useState();
  const [imageRatio, setImageRatio] = useState(1);
  const [initialized, setInitialized] = useState(false);
  const [imageTitle, setImageTitle] = useState("");
  const [attributes, setAttributes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [product, setProduct] = useState();
  const productLanguage =
    (productLanguages && productLanguages[0]?.code) || i18n.language;
  const productName = (product?.name && product?.name[productLanguage]) || "";
  const formRef = useRef();

  const imageStyle = {
    height: "auto",
    width: `${(imageRatio || 1) * 150}px`,
    objectFit: "contain",
    cursor: "pointer",
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const { enqueueSnackbar } = useSnackbar();

  const loadProduct = async () => {
    const productsPromise = getProducts(customerId).then((response) => {
      const { categories, attributes } = response || {};
      const attributesArr = Object.keys(attributes || {}).map((id) => ({
        ...attributes[id],
        id,
      }));
      const categoriesArr = Object.keys(categories || {}).map((id) => ({
        ...categories[id],
        id,
      }));
      setAttributes(attributesArr);
      setCategories(categoriesArr);
    });
    const productPromise = getProduct(url, customerId).then((response) => {
      const { category, attributes } = response.metadata;
      const product = {
        active: false,
        name: {
          sk: "",
          en: "",
          cz: "",
        },
        description: {
          sk: "",
          en: "",
          cz: "",
        },
        category: "",
        price: "",
        salePrice: "",
        weight: "",
        attributes: "",
        image: "",
      };
      setProduct({
        ...product,
        ...response.metadata,
        category: category?.split(",") || [],
        attributes: attributes?.split(",") || [],
      });
    });
    return await Promise.all([productsPromise, productPromise]);
  };

  useEffect(() => {
    loadProduct();
  }, [url]);

  const handleUpdateProduct = async (product) => {
    setUpdating(true);
    try {
      await updateProduct(product);
      await loadProduct();
      const message = `${t("buttons.edit")} ${t(
        "notifications.wasSuccessful"
      )}`;
      enqueueSnackbar(message, {
        variant: "success",
      });
    } catch (error) {
      const message = `${t("buttons.edit")} ${t("notifications.wasFailed")}`;
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
    setUpdating(false);
  };

  return (
    <>
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 1 }}>
        <Link to="/">{t("sidebar.menuItem.home")}</Link>
        <Link to="/products">{t("sidebar.menuItem.products")}</Link>
        <Typography color="text.primary">{productName}</Typography>
      </Breadcrumbs>
      <Div
        sx={{
          display: "flex",
        }}
      >
        <JumboCardQuick
          title={
            <Typography
              component={"div"}
              sx={{
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down("md")]: {
                  flexWrap: "wrap",
                },
              }}
            >
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  variant={"h4"}
                  mb={0}
                  sx={{
                    minWidth: 245,
                    [theme.breakpoints.down("md")]: {
                      minWidth: "100%",
                      marginBottom: 2,
                    },
                  }}
                >
                  {productName}
                </Typography>
                <Stack direction="row" spacing={2}>
                  <Link to={`/products`} style={{ textDecoration: "none" }}>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<FastRewindIcon />}
                      sx={{
                        height: "100%",
                      }}
                    >
                      {t("buttons.back")}
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    startIcon={<BrowserUpdatedIcon />}
                    sx={{
                      boxShadow: "none",
                    }}
                    size="small"
                    disabled={updating}
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.handleSubmit();
                      }
                    }}
                  >
                    {t("buttons.update")}
                  </Button>
                </Stack>
              </Div>
            </Typography>
          }
          headerSx={{
            borderBottom: 1,
            borderBottomColor: "divider",
            "& .MuiCardHeader-action": {
              my: -0.75,
            },
          }}
          sx={{
            width: "100%",
          }}
          wrapperSx={{
            p: 0,
            "&:last-child": {
              pb: 2,
            },
            "& .MuiCollapse-entered:last-child": {
              "& .MuiListItemButton-root": {
                borderBottom: 0,
                borderBottomColor: "transparent",
              },
            },
          }}
        >
          <>
            <Stack
              direction={"row"}
              spacing={2}
              display="flex"
              sx={{ mb: 2, width: "100%" }}
              alignItems="center"
            >
              {product && (
                <Formik
                  validateOnChange={true}
                  innerRef={formRef}
                  initialValues={product}
                  // validationSchema={ArticleCategorySchema}
                  onSubmit={async (data) => {
                    const value = {
                      ...data,
                      category:
                        data.category?.sort((a, b) => a - b).join(",") || "",
                      attributes:
                        data.attributes?.sort((a, b) => a - b).join(",") || "",
                      url,
                      customerId,
                    };
                    handleUpdateProduct(value);
                  }}
                >
                  {(props) => (
                    <Form
                      style={{ textAlign: "left", width: "100%" }}
                      noValidate
                    >
                      <Div sx={{ mt: 0, mb: 0, mx: 0, textAlign: "end" }}>
                        <FormControlLabel
                          control={
                            <Field
                              name={"active"}
                              component={Switch}
                              onChange={(e) =>
                                props.setFieldValue("active", e.target.checked)
                              }
                            />
                          }
                          label={t("pages.products.active")}
                          checked={props.values.active}
                        />
                      </Div>
                      <Stack
                        direction="row"
                        alignItems="center"
                        width="100%"
                        spacing={1}
                        px={2}
                      >
                        <Field
                          name={"image"}
                          component={() => (
                            <ImageListItem
                              sx={{
                                "& .MuiImageListItemBar-root": {
                                  transition:
                                    "visibility 0s, opacity 0.3s linear",
                                  opacity: 0,
                                },

                                "&:hover .MuiImageListItemBar-root": {
                                  visibility: "visible",
                                  opacity: 1,
                                },
                                width: "fit-content",
                                height: "auto",
                              }}
                            >
                              <Avatar
                                src={
                                  props.values.image ||
                                  "/images/pages/not_found.svg"
                                }
                                variant={"rounded"}
                                sx={imageStyle}
                                onLoad={(e) => {
                                  !initialized &&
                                    setImageRatio(
                                      e.target.offsetWidth /
                                        e.target.offsetHeight
                                    );
                                  setInitialized(true);
                                }}
                                onClick={() => setOpenGallerySelector(true)}
                              />
                              {product?.image && (
                                <ImageListItemBar
                                  subtitle={
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      sx={{
                                        mb: 0,
                                      }}
                                    >
                                      <Typography variant="body">
                                        {imageTitle}
                                      </Typography>
                                      <DeleteIcon
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          props.setFieldValue("image", "")
                                        }
                                      />
                                    </Stack>
                                  }
                                />
                              )}
                            </ImageListItem>
                          )}
                        />
                        <TabContext value={selectedLanguage}>
                          <Stack width="100%">
                            <Box
                              sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                              }}
                            >
                              <TabList
                                onChange={(_, val) => setSelectedLanguage(val)}
                                aria-label="lab API tabs example"
                              >
                                {productLanguages.map((lang) => {
                                  return (
                                    <Tab
                                      key={lang.code}
                                      label={lang.label}
                                      value={lang.code}
                                    />
                                  );
                                })}
                              </TabList>
                            </Box>
                            {productLanguages.map((lang) => {
                              return (
                                <TabPanel
                                  key={lang.code}
                                  value={lang.code}
                                  sx={{ pb: 1, pr: 0 }}
                                >
                                  <Div sx={{ mt: 0, mb: 1, mx: 0 }}>
                                    <JumboTextField
                                      fullWidth
                                      name={`name.${lang.code}`}
                                      label={t("pages.blocks.moduleName")}
                                    />
                                  </Div>
                                  <Div sx={{ mt: 2, mb: 0, mx: 0 }}>
                                    <JumboTextField
                                      fullWidth
                                      multiline
                                      rows={4}
                                      name={`description.${lang.code}`}
                                      label={t("pages.products.description")}
                                    />
                                  </Div>
                                </TabPanel>
                              );
                            })}
                          </Stack>
                        </TabContext>
                      </Stack>
                      <Stack direction="row" width="100%">
                        <FormControl
                          sx={{ mt: 1, mb: 1, mx: 1, pl: 1, pr: 0 }}
                          fullWidth
                        >
                          <JumboTextField
                            select
                            name="attributes"
                            label={t("pages.products.attributes")}
                            SelectProps={{
                              multiple: true,
                            }}
                          >
                            {attributes.map((attribute, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={attribute.metadata.number}
                                >
                                  {attribute.metadata[productLanguage]}
                                </MenuItem>
                              );
                            })}
                          </JumboTextField>
                        </FormControl>
                        <FormControl
                          sx={{ mt: 1, mb: 2, mx: 2, pl: 1, pr: 0 }}
                          fullWidth
                        >
                          <JumboTextField
                            select
                            name="category"
                            label={t("pages.products.categories")}
                            SelectProps={{
                              multiple: true,
                            }}
                          >
                            {categories.map((category, index) => {
                              return (
                                <MenuItem key={category.id} value={category.id}>
                                  {category.metadata.label[productLanguage]}
                                </MenuItem>
                              );
                            })}
                          </JumboTextField>
                        </FormControl>
                      </Stack>
                      <Stack direction="row" width="100%">
                        <Div sx={{ mt: 0, mb: 2, mx: 2, width: "100%" }}>
                          <JumboTextField
                            type="number"
                            fullWidth
                            name="price"
                            value={props.values.price}
                            label={t("pages.products.price")}
                            InputLabelProps={{ required: false }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                              onChange: (e) =>
                                props.setFieldValue("price", e.target.value),
                            }}
                          />
                        </Div>
                        <Div sx={{ mt: 0, mb: 2, mx: 2, width: "100%" }}>
                          <JumboTextField
                            type="number"
                            fullWidth
                            name="salePrice"
                            value={props.values.salePrice}
                            label={t("pages.products.salePrice")}
                            InputLabelProps={{ required: false }}
                            InputProps={{
                              inputProps: {
                                min: 0,
                              },
                              onChange: (e) =>
                                props.setFieldValue(
                                  "salePrice",
                                  e.target.value
                                ),
                            }}
                          />
                        </Div>
                        <Div sx={{ mt: 0, mb: 2, mx: 2, width: "100%" }}>
                          <JumboTextField
                            fullWidth
                            name="weight"
                            label={t("pages.products.weight")}
                          />
                        </Div>
                      </Stack>
                      <Modal
                        open={openGallerySelector !== undefined}
                        onClose={() => setOpenGallerySelector()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Div sx={modalStyle}>
                          <Images
                            layout={"row-reverse"}
                            selectorMode
                            onCancel={() => setOpenGallerySelector(false)}
                            onSelect={(image) => {
                              const imageUrl = `${PROD_URL}/uploads/files/${customerId}/Images/${image.filename}`;
                              setImageTitle(image.label);
                              props.setFieldValue("image", imageUrl);
                              setOpenGallerySelector();
                            }}
                          />
                        </Div>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              )}
            </Stack>
          </>
        </JumboCardQuick>
      </Div>
    </>
  );
};

export default EditProduct;
