import React from "react";
import {
  Grid,
  FormControlLabel,
  Button,
  FormControl,
  MenuItem,
} from "@mui/material";
import { Field } from "formik";
import Switch from "@mui/material/Switch";

import Div from "@jumbo/shared/Div";
import { Label } from "app/pages/presentation/module/form/types";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DateTimeField, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { parseISO } from "date-fns";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import { BE_DOMAINS, CURRENCY_CODES } from "app/utils/constants/settings";

const GeneralSection = ({
  device,
  setFieldValue,
  values,
  t,
  isAdmin,
  isPartnerAdmin,
  isPartner,
  previewButtonLink,
  callScreenUrl,
  isFrontendDemoDevice,
  manuelChecking,
  handleManualCheckDevice,
}) => (
  <>
    <Grid item xs={12} md={4} lg={4}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <FormControlLabel
          control={
            <Field
              name={`customer.devices.${device}.forceMaintenance`}
              component={Switch}
              onChange={(e) =>
                setFieldValue(
                  `customer.devices.${device}.forceMaintenance`,
                  e.target.checked
                )
              }
            />
          }
          label={
            <Label
              text={t("pages.dsokDetails.forceMaintenance")}
              helpText={t("pages.dsokDetails.forceMaintenanceDeviceHelp")}
              sx={{ color: "text.primary" }}
              required={false}
            />
          }
          checked={values.customer.devices[device].forceMaintenance}
        />
      </Div>
    </Grid>
    <Grid item xs={12} md={4} lg={4}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <FormControlLabel
          control={
            <Field
              name={`customer.devices.${device}.maintenance`}
              component={Switch}
              onChange={(e) =>
                setFieldValue(
                  `customer.devices.${device}.maintenance`,
                  e.target.checked
                )
              }
            />
          }
          label={
            <Label
              text={t("pages.dsokDetails.enableMaintenance")}
              helpText={t("pages.dsokDetails.enableMaintenanceDeviceHelp")}
              sx={{ color: "text.primary" }}
              required={false}
            />
          }
          checked={values.customer.devices[device].maintenance}
        />
      </Div>
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        {/* frontend demo device */}
        <FormControlLabel
          control={
            <Field
              name={`customer.devices.${device}.demoMode`}
              component={Switch}
              onChange={(e) =>
                setFieldValue(
                  `customer.devices.${device}.demoMode`,
                  e.target.checked
                )
              }
            />
          }
          label={
            <Label
              text={t("pages.dsokDetails.demoMode")}
              color="primary"
              helpText={t("pages.dsokDetails.demoModeHelp")}
            />
          }
          checked={values.customer.devices[device].demoMode}
        />
      </Div>
    </Grid>
    <Grid item xs={12} md={6} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        {/* frontend demo device */}
        <FormControlLabel
          control={
            <Field
              name={`customer.devices.${device}.testMode`}
              component={Switch}
              onChange={(e) =>
                setFieldValue(
                  `customer.devices.${device}.testMode`,
                  e.target.checked
                )
              }
            />
          }
          label={
            <Label
              text={t("pages.dsokDetails.testMode")}
              color="primary"
              helpText={t("pages.dsokDetails.testModeHelp")}
            />
          }
          checked={values.customer.devices[device].testMode}
        />
      </Div>
    </Grid>
    <Grid item xs={12} md={12} lg={12}></Grid>
    <Grid item xs={12} md={3} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <JumboTextField
          required
          fullWidth
          InputProps={{
            readOnly: isAdmin ? false : true,
          }}
          InputLabelProps={{ required: false }}
          name={`customer.devices.${device}.deviceName`}
          label={<Label text={t("pages.dsokDetails.deviceName")} required />}
        ></JumboTextField>
      </Div>
    </Grid>
    <Grid item xs={12} md={3} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <JumboTextField
          required
          fullWidth
          InputLabelProps={{ required: false }}
          InputProps={{
            readOnly: isAdmin ? false : true,
          }}
          name={`customer.devices.${device}.serialNumber`}
          label={<Label text={t("pages.dsokDetails.serialNumber")} required />}
        ></JumboTextField>
      </Div>
    </Grid>
    <Grid item xs={12} md={3} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimeField
            label={t("pages.dsokDetails.lastHeartbeat")}
            name={`customer.devices.${device}.lastHeartbeat`}
            readOnly
            slotProps={{
              textField: {
                InputLabelProps: { shrink: true },
                placeholder: "",
              },
            }}
            value={
              values.customer.devices[device].lastHeartbeat &&
              parseISO(values.customer.devices[device].lastHeartbeat)
            }
          />
        </LocalizationProvider>
      </Div>
    </Grid>
    <Grid item xs={12} md={3} lg={3}>
      <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
        <JumboTextField
          required
          select
          InputLabelProps={{ required: false }}
          name={`customer.devices.${device}.beDomain`}
          label={<Label text={t("pages.dsokDetails.beDomain")} required />}
        >
          {BE_DOMAINS.map((domain) => {
            return (
              <MenuItem key={domain} value={domain}>
                {domain}
              </MenuItem>
            );
          })}
        </JumboTextField>
      </Div>
    </Grid>
    <Grid item xs={12} md={12} lg={12}></Grid>
    {isAdmin && (
      <>
        <Grid item xs={12} md={2} lg={2}>
          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
            <JumboTextField
              // required
              select
              // InputLabelProps={{ required: true }}
              name={`customer.devices.${device}.currency`}
              label={<Label text={t("pages.dsokDetails.currency")} required />}
            >
              {CURRENCY_CODES.map(({ code, name }) => {
                return (
                  <MenuItem key={code} value={code}>
                    {name}
                  </MenuItem>
                );
              })}
            </JumboTextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
            <JumboTextField
              required
              select
              InputLabelProps={{ required: false }}
              name={`customer.devices.${device}.location`}
              label={<Label text={t("pages.dsokDetails.location")} required />}
            >
              {Object.keys(values?.customer.locations || {}).map((location) => {
                return (
                  <MenuItem key={location} value={location}>
                    {values?.customer.locations[location].code}
                  </MenuItem>
                );
              })}
            </JumboTextField>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <Div sx={{ mt: 1, mb: 1 }}>
            <JumboTextField
              fullWidth
              required
              InputProps={{
                readOnly: true,
              }}
              name={`customer.devices.${device}.uuid`}
              label={t("pages.dsokDetails.uuid")}
            />
          </Div>
        </Grid>
      </>
    )}
    <Grid item xs={12} md={12} lg={12}></Grid>
    {(isAdmin || isPartnerAdmin) && (
      <>
        <Grid item xs={12} md={4} lg={4}>
          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
            <Link
              to={previewButtonLink}
              style={{
                textDecoration: "none",
                alignSelf: "center",
              }}
              target="_blank"
            >
              <Button
                variant="contained"
                startIcon={<OpenInNewIcon />}
                sx={{
                  boxShadow: "none",
                  height: "38px",
                }}
                size="small"
              >
                {t("pages.dsokDetails.openFrontend")}
              </Button>
            </Link>
          </Div>
        </Grid>
      </>
    )}
    {(isAdmin || isPartnerAdmin) && callScreenUrl !== null && (
      <>
        <Grid item xs={12} md={4} lg={4}>
          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
            <Link
              to={callScreenUrl}
              style={{
                textDecoration: "none",
                alignSelf: "center",
              }}
              target="_blank"
            >
              <Button
                variant="contained"
                startIcon={<OpenInNewIcon />}
                sx={{
                  boxShadow: "none",
                  height: "38px",
                }}
                size="small"
              >
                {t("pages.dsokDetails.callScreenOpen")}
              </Button>
            </Link>
          </Div>
        </Grid>
      </>
    )}
    {(isAdmin || isPartnerAdmin) && !isFrontendDemoDevice && (
      <Grid item xs={12} md={4} lg={4}>
        <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
          <Button
            variant="contained"
            size="small"
            sx={{
              boxShadow: "none",
              height: "38px",
            }}
            disabled={manuelChecking}
            onClick={() => handleManualCheckDevice(device)}
          >
            {t("pages.dsokDetails.manuelCheck")}
          </Button>
        </Div>
      </Grid>
    )}
    {/* {isPartner && (
      <>
        <Grid item xs={12} md={4} lg={2}>
          <FormControl sx={{ mt: 1, mb: 1 }} fullWidth>
            <JumboTextField
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              InputLabelProps={{ required: false }}
              name={`customer.devices.${device}.deviceName`}
              label={
                <Label text={t("pages.dsokDetails.deviceName")} required />
              }
            ></JumboTextField>
          </FormControl>
        </Grid>
      </>
    )} */}
    <Grid item xs={12} md={12} lg={12}></Grid>
    {isAdmin && (
      <>
        <Grid item xs={12} md={4} lg={4}>
          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
            <FormControlLabel
              control={
                <Field
                  name={`customer.devices.${device}.orderProcess.enablePrintReceipt`}
                  component={Switch}
                  onChange={(e) =>
                    setFieldValue(
                      `customer.devices.${device}.orderProcess.enablePrintReceipt`,
                      e.target.checked
                    )
                  }
                />
              }
              label={
                <Label
                  text={t("pages.dsokDetails.enablePrintReceipt")}
                  color="primary"
                  helpText={t("pages.dsokDetails.enablePrintReceiptHelp")}
                />
              }
              checked={
                values.customer.devices[device].orderProcess?.enablePrintReceipt
              }
            />
          </Div>
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <Div sx={{ mt: 2, mb: 1, mx: 0.5 }}>
            <FormControlLabel
              control={
                <Field
                  name={`customer.devices.${device}.restartApp`}
                  component={Switch}
                  onChange={(e) =>
                    setFieldValue(
                      `customer.devices.${device}.restartApp`,
                      e.target.checked
                    )
                  }
                />
              }
              label={
                <Label
                  text={t("pages.dsokDetails.restartApp")}
                  helpText={t("pages.dsokDetails.restartAppHelp")}
                  sx={{ color: "text.primary" }}
                  required={false}
                />
              }
              checked={values.customer.devices[device].restartApp}
            />
          </Div>
        </Grid>
      </>
    )}
  </>
);

export default GeneralSection;
